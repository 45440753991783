<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-right">Tickets</h1>
    </div>

    <div class="row my-3">
      <div class="col-sm-12 text-right">
        <button class="btn btn-primary" @click="modal_nuevo=true">Nuevo ticket</button>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-sm-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a :class="get_class('activos')" href="#" @click.prevent="tab_activo='activos'">Tickets activos</a>
          </li>
          <li class="nav-item">
            <a :class="get_class('inactivos')" href="#" @click.prevent="tab_activo='inactivos'">Tickets cerrados</a>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="tab_activo == 'activos'" class="tickets_activos">
      <div class="row" v-if="tickets_abiertos.length > 0">
        <div v-for="ticket in tickets_abiertos" :key="ticket.id" class="col-sm-3 my-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Ticket: {{ ticket.ticket }}</h5>
              <div class="card-text info_ticket"><span class="info_ticket_span">Por:</span> {{ ticket.nombre }}</div>
              <div class="card-text info_ticket"><span class="info_ticket_span">Etapa:</span> {{ ticket.etapas[0].etapa.etapa }}</div>
              <div class="card-text info_ticket"><span class="info_ticket_span">Creación:</span> {{ $moment(ticket.created_at).format('dd DD MMM YYYY') }}</div>
              <div class="card-text info_ticket"><span class="info_ticket_span">Asignación:</span> {{ ticket.usuario_asignado_nombre ? ticket.usuario_asignado_nombre : 'N/A' }}</div>
              <div class="card-text info_ticket"><span class="info_ticket_span">Fecha asignación:</span> {{ ticket.asignacion_at ? $moment(ticket.asignacion_at).format('dd DD MMM YYYY') : 'N/A' }}</div>
              <div class="text-right">
                <button class="btn btn-secondary" @click="ver_detalles(ticket)">Ver detalles...</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-sm-12">No hay tickets abiertos</div>
      </div>
    </div>

    <div v-if="tab_activo == 'inactivos'" class="tickets_inactivos">
      <div class="row" v-if="tickets_cerrados.length > 0">
        <div v-for="ticket in tickets_cerrados" :key="ticket.id" class="col-sm-3 my-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Ticket: {{ ticket.ticket }}</h5>
              <div class="card-text info_ticket"><span class="info_ticket_span">Por:</span> {{ ticket.nombre }}</div>
              <div class="card-text info_ticket"><span class="info_ticket_span">Etapa:</span> {{ ticket.etapas.length > 0 ? ticket.etapas[0].etapa.etapa : 'N/A' }}</div>
              <div class="card-text info_ticket"><span class="info_ticket_span">Creación:</span> {{ $moment(ticket.created_at).format('dd DD MMM YYYY') }}</div>
              <div class="card-text info_ticket"><span class="info_ticket_span">Asignación:</span> {{ ticket.asignacion ? ticket.asignacion.nombre : 'N/A' }}</div>
              <div class="card-text info_ticket"><span class="info_ticket_span">Fecha asignación:</span> {{ ticket.asingacion_at ? $moment(ticket.asingacion_at).format('dd DD MMM YYYY') : 'N/A' }}</div>
              <div class="text-right">
                <button class="btn btn-secondary" @click="ver_detalles(ticket)">Ver detalles...</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-sm-12">No hay tickets cerrados</div>
      </div>
    </div>

    <Nuevo v-if="modal_nuevo" @close="cerrar_modal_nuevo" @close_update="cerrar_modal_nuevo_actualizar" />
    <Detalle v-if="modal_detalles" :org_ticket="ticket" @close="modal_detalles=false" />
  </div>
</template>

<script>
  import Nuevo from './Ticket/Nuevo'
  import Detalle from './Ticket/Detalle'

  import apiTickets from '@/apps/constelacion/api/tickets'

  export default {
    components:{
      Nuevo, Detalle
    },
    data() {
      return {
        tab_activo: 'activos',
        modal_nuevo: false,
        modal_detalles: false,
        tickets: [],
        ticket: {
          area: null
          ,requerimiento_id: null

        }
      }
    }
    ,mounted() {
      this.$store.commit('setMenu',[]);
      this.obtener_tickets();
    }
    ,methods: {
      get_class(activo) {
        return 'nav-link'+(this.tab_activo == activo ? ' active' : '');
      }
      ,async obtener_tickets() {
        try {
          this.tickets = (await apiTickets.listar_tickets()).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_modal_nuevo() {
        this.ticket = {
          area: null
          ,requerimiento_id: null

        }
        this.modal_nuevo = false;
      }
      ,cerrar_modal_nuevo_actualizar() {
        this.cerrar_modal_nuevo();
        this.obtener_tickets();
      }
      ,ver_detalles(ticket) {
        this.ticket = ticket;
        this.modal_detalles = true;
      }
    }
    ,computed: {
      tickets_abiertos() {
        let tickets = [];

        for(let i=0; i<this.tickets.length; i++) {
          if (this.tickets[i].estatus == 1)
            tickets.push(this.tickets[i]);
        }

        return tickets;
      }
      ,tickets_cerrados() {
        let tickets = [];

        for(let i=0; i<this.tickets.length; i++) {
          if (this.tickets[i].estatus != 1)
            tickets.push(this.tickets[i]);
        }

        return tickets;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info_ticket {
    background-color: #E6E6E6;
    font-size: 0.8em;
    margin-bottom: 5px;

    .info_ticket_span {
      width: 30%;
      background-color: #999999;
      color: #fff;
      margin-right: 5px;
      padding: 5px;
      display:inline-block;
    }
  }
</style>